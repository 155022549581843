<div class="container-fluid my-4">
  <mat-card appearance="outlined" class="example-card">
    <mat-card-header>
      <mat-card-title>
        <app-language-select></app-language-select>
      </mat-card-title>
    </mat-card-header>
    <!-- <div class="text-center mb-4">
      <img src="../../../assets/img/matchedin/matchedin_logo_stor.png" style="height: 130px"
        alt="matchedin_logo_stor" />
    </div> -->
    <div class="text-center">
        <h1>{{ "HOME.TITLE" | translate }}</h1>
        <p>{{ "HOME.INTRO" | translate }}</p>
        <!-- <button mat-stroked-button 
          *ngIf="authService.loggedIn() && !profileNotComplete" 
          [routerLink]="['/app/mypage/account']" 
          color="primary">{{ 'HOME.HOME' | translate | uppercase }}
        </button>
        <button mat-stroked-button 
          *ngIf="authService.loggedIn() && profileNotComplete" 
          [routerLink]="['app/mypage/stepper']" 
          color="primary">{{ 'HOME.START' | translate | uppercase }}
        </button> -->
        <button mat-stroked-button 
          *ngIf="authService.loggedIn()" 
          [routerLink]="['/app/mypage/account']" 
          color="primary">{{ 'HOME.HOME' | translate | uppercase }}
        </button>
        <!-- <button mat-stroked-button 
          *ngIf="authService.loggedIn() && profileNotComplete" 
          [routerLink]="['app/mypage/stepper']" 
          color="primary">{{ 'HOME.START' | translate | uppercase }}
        </button> -->
        <div class="my-5" style="width: 40%; margin: auto">
          <img mat-card-image src="../../../assets/img/smartmockups_laptop.png"
            alt="smartmockups_laptop" />
        </div>
    </div>
  </mat-card>

  <!-- <div class="text-center my-5">
      <a href="http://matchedin.se/">
        <img
          src="../../../assets/img/smartmockups_laptop.png"
          style="height: 400px"
          alt="smartmockups_laptop"
          matTooltip="{{ 'LOGIN.TO_WEB' | translate }}"
        />
      </a>
    </div> -->
</div>
