import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS
} from '@angular/common/http';

// App is the top level component

import {
  AuthService,
  AuthGuard,
  SiteAdminGuard,
  AdministerGuard,
  AdministratorSiteAdminGuard,
  StateService,
  LanguageUtilService,
} from './modules/auth';
import { CommonModule } from '@angular/common';
import { LoginModule } from './modules/login/login.module';
import { LoginModalComponent } from './modules/login/login-modal/login-modal.component';
import { LogoutWarningComponent } from './modules/login/logout-warning/logout-warning.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { MaterialModule } from './material.module';

import { ROUTES } from './app.routes';
import { AppComponent } from './app.component';
import { ErrorComponent } from './modules/error/error.component';
import { InterceptHttpModule } from './modules/http/index';
import { SharedModule } from './modules/shared/shared.module';
import { CommunicationService } from './helpers/services/communication.service';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { LoginComponent } from './modules/login/login/login.component';
import { HomeComponent } from './modules/home/home.component';
import { IntegrityPolicyComponent } from './components/integrity-policy/integrity-policy.component';
import { ToastersModule } from './modules/toasters/toasters.module';
import { JWTInterceptor } from './jwtInterceptor';
import { NewTokenInterceptor } from './newTokenInterceptor';

@NgModule({
    declarations: [
        AppComponent,
        ErrorComponent,
        LoginModalComponent,
        LogoutWarningComponent,
        SidenavComponent,
        HomeComponent,
        IntegrityPolicyComponent
    ],
    imports: [
        ReactiveFormsModule,
        BrowserModule,
        FormsModule,
        HttpClientModule,
        CommonModule,
        InterceptHttpModule,
        LoginModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(ROUTES, { useHash: true }),
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        SharedModule,
        MaterialModule,
        ToastersModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JWTInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: NewTokenInterceptor,
            multi: true
        },
        AuthService,
        AuthGuard,
        SiteAdminGuard,
        AdministerGuard,
        AdministratorSiteAdminGuard,
        StateService,
        LanguageUtilService,
        CommunicationService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
