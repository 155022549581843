import { forkJoin as observableForkJoin, Subscription, Observable } from 'rxjs';
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { LoginResult } from '../../auth/loginResult';
import { AuthService } from '../../auth/auth.service';
import { StateService } from '../../auth/state.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { LanguageUtilService } from '../../auth/language-util.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit, OnDestroy {
  lang: any;
  subscription: Subscription;
  public errorMessage: any;
  public email: string;
  private hostPath: string = JSON.parse(environment.IDENTITY);

  frm: UntypedFormGroup;

  constructor(
    public authService: AuthService,
    public stateService: StateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    public translate: TranslateService,
    private languageUtilService: LanguageUtilService,
    private _snackBar: MatSnackBar,
    private fb: UntypedFormBuilder,
  ) {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params['language']) {
        this.lang = params['language'];
        this.languageUtilService.setDefaultLang(this.lang);
        this.languageUtilService.changeLang(this.lang);
      }
    });
    this.subscription = this.languageUtilService.langChangeObservable.subscribe(
      event => {
        this.lang = event;
        this.router.navigate(['/'], {
          queryParams: { language: this.lang }
        });
      }
    );
  }

  ngOnInit() {
    this.frm = this.fb.group({
      username: this.fb.control(null, Validators.compose([Validators.required, Validators.email])),
    });
  }

  public forgotPassword() {
    const frmValue = this.frm.value;
    // alert(this.languageUtilService.getCurrentLang());
    this.stateService.forgotPassword(frmValue.username, this.languageUtilService.getCurrentLang()).subscribe(
      (result: LoginResult) => {
        console.log(result);

        let message = '';
        this.translate.get('ERROR_MSG.FORGOT_PASSWORD_SUCCESS').subscribe((res: string) => {
          message += res;
        });

        this._snackBar.open(message, '', {
          duration: 5000,
        });
        this.router.navigate(['/home']);
      },
      (error: LoginResult) => {
        console.log(error);
        let message = '';
        this.translate.get('ERROR_MSG.SERVER_ERROR').subscribe((res: string) => {
          message += res;
        });

        this._snackBar.open(message, '', {
          duration: 5000,
        });
      }
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
