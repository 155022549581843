import { forkJoin as observableForkJoin, Subscription, Observable } from 'rxjs';
import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectorRef,
  OnDestroy
} from '@angular/core';
import { LoginResult } from '../../auth/loginResult';
import { AuthService } from '../../auth/auth.service';
import { StateService } from '../../auth/state.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { LanguageUtilService } from '../../auth/language-util.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  lang: any;
  subscription: Subscription;
  public errorMessage: any;
  public password: string;
  public confirmpassword: string;
  public hostPath = JSON.parse(environment.IDENTITY);
  public code: string;
  public passwordcriteriafail: boolean = false;
  public resetpasswordCodeExpired: boolean = false;
  public resetpasswordCodeError: boolean = false;

  constructor(
    public authService: AuthService,
    public stateService: StateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    public translate: TranslateService,
    private languageUtilService: LanguageUtilService,
    private _snackBar: MatSnackBar
  ) {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.code = params['code'];
      if (params['language']) {
        this.lang = params['language'];
        this.languageUtilService.setDefaultLang(this.lang);
        this.languageUtilService.changeLang(this.lang);
      }
    });
    this.subscription = this.languageUtilService.langChangeObservable.subscribe(
      event => {
        this.lang = event;
        this.router.navigate(['/resetpassword'], {
          queryParams: { code: this.code, language: this.lang }
        });
      }
    );
  }

  ngOnInit() {
    // this.authService.logout();
    // this.username = 'operations@molndrift.com';
    // this.password = 'password';
    // this.smsCode = '';
    // if (this.authService.loggedIn) {
    //     this.router.navigate(['/app/dashboard']);
    // }
  }

  public resetPassword() {
    this.passwordcriteriafail = false;
    this.resetpasswordCodeExpired = false;
    this.resetpasswordCodeError = false;
    this.stateService.resetPassword(this.code, this.password).subscribe(
      (result: LoginResult) => {
        if (result.status === 21) {
          this.passwordcriteriafail = true;
          this.changeDetectorRef.markForCheck();
        } else if (result.status === 27) {
          this.resetpasswordCodeExpired = true;
          this.changeDetectorRef.markForCheck();
        } else if (result.status === 28) {
          this.resetpasswordCodeError = true;
          this.changeDetectorRef.markForCheck();
        } else {
          let message = '';
          this.translate.get('ERROR_MSG.RESET_PASSWORD_SUCCESS').subscribe((res: string) => {
            message += res;
          });

          this._snackBar.open(message, '', {
            duration: 5000,
          });
          this.router.navigate(['/login']);
        }
      },
      (error: LoginResult) => {
        console.log(error);
        this.errorMessage = 'Login failed';
      }
    );
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
