<div class="container my-4 text-center">
  <mat-card appearance="outlined">
    <h3>{{ "ACTIVATE_USER.ACTIVATE_ACCOUNT" | translate }}</h3>
    <p>{{ "ACTIVATE_USER.SET_PASSWORD" | translate }}</p>
    <div class="appLogo text-center my-4">
      <img src="../../../../assets/img/matchedin/matchedin_logo_stor.png" />
    </div>
    <!-- <div style="width: 10%">
      <app-language-select></app-language-select>
    </div> -->
    <form
      action="index.html"
      method="get"
      #f="ngForm"
      (ngSubmit)="f.form.valid && activateUser()"
    >
      <mat-form-field *ngIf="!code">
        <input
          matInput
          placeholder="{{ 'ACTIVATE_USER.CODE' | translate }}"
          id="code"
          [(ngModel)]="code"
          name="code"
        />
      </mat-form-field>
      <br />
      <mat-form-field *ngIf="code">
        <input
          matInput
          id="password"
          [(ngModel)]="password"
          name="password"
          type="password"
          placeholder="{{ 'ACTIVATE_USER.PASSWORD' | translate }}"
        />
      </mat-form-field>
      <br />
      <mat-form-field *ngIf="code">
        <input
          matInput
          id="confirmpassword"
          [(ngModel)]="confirmpassword"
          name="confirmpassword"
          type="password"
          placeholder="{{ 'ACTIVATE_USER.CONFIRM_PASSWORD' | translate }}"
        />
      </mat-form-field>
      <br />
      <app-password-strength
        [passwordToCheck]="password"
        [controlLabel]=""
        (onStatusChange)="isValid($event)"
      ></app-password-strength>
      <mat-checkbox
        (click)="openDialog()"
        name="terms"
        [(ngModel)]="isTermsChecked"
        matTooltip="Info about the action"
        required
      >
        {{ "ACTIVATE_USER.I_AGREE" | translate }}
        {{ "ACTIVATE_USER.PRIVACY_POLOCY" | translate }}
      </mat-checkbox>
      <mat-card-actions class="my-4">
        <button mat-raised-button color="primary">
          <i class="material-icons">
            play_arrow
          </i>
          {{ "ACTIVATE_USER.SET_PASSWORD_BTN" | translate }}
        </button>
      </mat-card-actions>
    </form>
  </mat-card>
</div>

<!-- <div class="single-widget-container">
  <section class="widget login-widget">
    <div class="appLogo text-center MB20">
      <img src="../../../../assets/img/matchedin/matchedin_logo_stor.png" />
    </div>
    <header class="text-xs-center">
      <h4>{{ "ACTIVATE_USER.ACTIVATE_ACCOUNT" | translate }}</h4>
      - {{ "ACTIVATE_USER.SET_PASSWORD" | translate }}
    </header>
    <div class="pull-right">
      <label>{{ "LOGIN.SELECT" | translate }}</label>
      <app-language-select></app-language-select>
    </div>
    <div class="body">
      <form
        class="no-margin"
        action="index.html"
        method="get"
        #f="ngForm"
        (ngSubmit)="f.form.valid && activateUser()"
      >
        <fieldset class="login-form">
          <div class="form-group" *ngIf="!code">
            <label for="Code">{{ "ACTIVATE_USER.CODE" | translate }}</label>
            <div class="input-group input-group-lg">
              <span class="input-group-addon input-group-prepend">
                <i class="fa fa-lock input-group-text"></i>
              </span>
              <input
                id="code"
                [(ngModel)]="code"
                name="code"
                type="text"
                class="form-control input-lg input-transparent"
                placeholder="{{ 'ACTIVATE_USER.CODE' | translate }}"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="password">{{
              "ACTIVATE_USER.PASSWORD" | translate
            }}</label>
            <div class="input-group input-group-lg">
              <span class="input-group-addon input-group-prepend">
                <i class="fa fa-lock input-group-text"></i>
              </span>
              <input
                id="password"
                [(ngModel)]="password"
                name="password"
                type="password"
                class="form-control input-lg input-transparent"
                placeholder="{{ 'ACTIVATE_USER.PASSWORD' | translate }}"
              />
            </div>
            <app-password-strength
              [passwordToCheck]="password"
              [controlLabel]=""
              (onStatusChange)="isValid($event)"
            ></app-password-strength>
          </div>
          <div class="form-group">
            <label for="confirmpassword">{{
              "ACTIVATE_USER.CONFIRM_PASSWORD" | translate
            }}</label>
            <div class="input-group input-group-lg">
              <span class="input-group-addon input-group-prepend">
                <i class="fa fa-lock input-group-text"></i>
              </span>
              <input
                id="confirmpassword"
                [(ngModel)]="confirmpassword"
                name="confirmpassword"
                type="password"
                class="form-control input-lg input-transparent"
                placeholder="{{ 'ACTIVATE_USER.CONFIRM_PASSWORD' | translate }}"
              />
            </div>
          </div>
          <div class="form-check MB20">
            <input
              type="checkbox"
              name="terms"
              class="form-check-input"
              [(ngModel)]="isTermsChecked"
              required
            />
            <label class="form-check-label" for="exampleCheck1">
              {{ "ACTIVATE_USER.I_AGREE" | translate }}

              <a
                href="/assets/files/matchedin_integritetsvillkor.pdf"
                class="clickable"
                target="_blank"
              >
                {{ "ACTIVATE_USER.PRIVACY_POLOCY" | translate }}</a
              >
            </label>
          </div>
          <div>
            <span
              class="bg-danger text-white"
              *ngIf="f.submitted && !isTermsChecked"
            >
              {{ "ACTIVATE_USER.PLEASE_ACCEPT_TERMS" | translate }}.</span
            >
          </div>
          <div>
            <span
              class="bg-danger text-white"
              *ngIf="f.submitted && showPasswordStrength"
              >{{ "ACTIVATE_USER.INVALID_PASSWORD" | translate }}.
            </span>
          </div>
        </fieldset>
        <div class="form-actions">
          <div *ngIf="passwordcriteriafail">passwordcriteriafail</div>
          <div *ngIf="activationCodeExpired">
            {{ "ACTIVATE_USER.ACTIVATIONCODEEXPIRED" | translate }}
          </div>
          <div *ngIf="activationCodeError">activationCodeError</div>
          <button type="submit" class="btn btn-block btn-danger">
            <span class="small-circle">
              <i class="fa fa-caret-right"></i>
            </span>
            <small> {{ "ACTIVATE_USER.SET_PASSWORD_BTN" | translate }}</small>
          </button>
          <a routerLink="/login" class="forgot">{{
            "FORGOT_PASSWORD.LOGIN" | translate
          }}</a>
        </div>
      </form>
    </div>
    <footer></footer>
  </section>
</div> -->

<ng-template #terms>
  <div class="modal-header">
    <h4 class="modal-title text-center">Terms and conditions</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-12">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
          nisl nunc, semper at ipsum non, vulputate volutpat ante. Curabitur
          ultrices rutrum dictum. Nunc id maximus odio. Praesent bibendum
          ultricies velit nec placerat. Sed eu varius arcu. Suspendisse sagittis
          id elit et elementum. Etiam dapibus lacinia diam eu venenatis. Mauris
          consequat purus sed magna vestibulum egestas. Suspendisse interdum
          quam eu arcu faucibus, vitae hendrerit lacus semper. Nam convallis
          erat libero, et pellentesque mi cursus vitae. Sed facilisis felis
          massa, nec mollis augue pretium vel. Orci varius natoque penatibus et
          magnis dis parturient montes, nascetur ridiculus mus. Nulla lacinia
          justo eu hendrerit dictum. Nunc porta in ante a laoreet. Maecenas
          commodo mauris nulla, vitae lacinia enim sagittis non. Maecenas
          maximus cursus nisi, in malesuada quam lobortis blandit.
        </p>
        <p>
          Phasellus in rutrum justo. Vestibulum ultricies viverra mattis.
          Phasellus varius aliquam posuere. Aliquam non purus dapibus, tristique
          eros eu, fringilla augue. Phasellus finibus nunc consectetur eros
          viverra vehicula sit amet vitae arcu. Sed sagittis magna commodo
          aliquet suscipit. Aenean vestibulum quam non lectus dapibus aliquet.
        </p>
        <p>
          Ut egestas nisi dolor, ac blandit quam posuere eu. Etiam faucibus,
          quam eget ornare tempor, arcu dui viverra felis, id condimentum arcu
          arcu id urna. Fusce facilisis efficitur arcu, eget vulputate felis
          pretium convallis. Aenean ultricies commodo dolor ac pretium. Integer
          porttitor ac sapien sed condimentum. Integer in maximus dolor. Nulla
          tristique aliquet mauris luctus pretium. Praesent eget suscipit nulla,
          quis tempus eros. Quisque sed pellentesque tellus. Cras nec lobortis
          sapien. Ut sed condimentum nulla. Ut sodales elit quis ipsum tristique
          maximus. Curabitur sed sagittis augue. Pellentesque cursus ex ut
          sapien vehicula, sed ultricies quam commodo. Etiam eget erat diam.
          Nullam ullamcorper bibendum mauris at fermentum. Vestibulum quis ante
          sollicitudin, venenatis ex id, maximus lorem. Vivamus rhoncus metus
          non ultrices auctor. Donec sit amet urna ac ipsum vehicula tempus.
          Morbi ac neque quam. Vivamus a ligula a risus fermentum volutpat. In
          sollicitudin nisi ligula, quis rutrum diam lobortis non.
        </p>
        <p>
          Nam rutrum tortor eu facilisis egestas. Etiam lectus tortor,
          ullamcorper et ullamcorper nec, mollis nec sem. Nam sit amet commodo
          ligula. Nullam eu faucibus mi, sit amet finibus massa. Donec mattis
          purus sed vestibulum rutrum. Nam et eros a metus egestas hendrerit ut
          in arcu. Aliquam fermentum nunc vitae metus placerat tincidunt. Nulla
          in cursus ipsum. Duis eget ornare orci, ac pharetra ligula. Praesent
          elit sem, fringilla in dolor sed, maximus eleifend erat. Vivamus
          interdum sem ornare lacus elementum consectetur. Ut euismod libero
          quis libero vestibulum ornare.
        </p>
      </div>
    </div>
  </div>
</ng-template>
