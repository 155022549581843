<div class="container text-center">
  <div class="text-center">
    <img src="\assets\img\matchedin\matchedin_logo_stor.png" style="height: 65px">
  </div>
  <br>
  <header class="text-center">
    <h3>{{'LOGIN.TITLE' | translate}}</h3>
  </header>
  <div class="text-centern mt-5">
    <app-language-select></app-language-select>
  </div>
  <form class="no-margin" [formGroup]="frm" (ngSubmit)="login()" *ngIf="!showSmsCode">
    <mat-form-field class="example-container" *ngIf="!showSmsCode">
      <input matInput placeholder="{{'LOGIN.EMAIL' | translate}}" formControlName="username" id="username"
        name="username" type="email">
      <button mat-icon-button tabindex="-1" matSuffix>
        <mat-icon>mail_outline</mat-icon>
      </button>
      <mat-error *ngIf="frm.get('username').errors?.required">{{ 'ERROR_MSG.STEPPER' | translate }}</mat-error>
      <mat-error *ngIf="frm.get('username').errors?.email">{{ 'ERROR_MSG.EMAIL' | translate }}</mat-error>
    </mat-form-field>

    <mat-form-field class="example-container" *ngIf="!showSmsCode">
      <input matInput placeholder="{{'LOGIN.PASSWORD' | translate}}" [type]="hide ? 'password' : 'text'" id="password"
        formControlName="password" name="password">
      <mat-hint align="end" (click)="gotForgotPassword()" class="clickable">{{'LOGIN.FORGOT' | translate}}?</mat-hint>
      <button mat-icon-button tabindex="-1" matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hide">
        <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
      </button>
      <mat-error *ngIf="frm.get('password').errors?.required">{{ 'ERROR_MSG.STEPPER' | translate }}</mat-error>
      <mat-error *ngIf="showServerError">{{'LOGIN.ERROR' | translate}}</mat-error>
    </mat-form-field>
    <!-- <mat-error *ngIf="showServerError">{{'LOGIN.ERROR' | translate}}</mat-error> -->
    <br>
    <br>
    <button mat-raised-button color="primary" [disabled]="!frm.valid" type="submit">
      {{'LOGIN.SIGN_IN' | translate | uppercase}}
    </button>
  </form>

  <form class="no-margin" [formGroup]="smsFrm" (ngSubmit)="login()" *ngIf="showSmsCode">
    <mat-form-field class="example-container">
      <input matInput placeholder="{{'LOGIN_MODAL.SMS_CODE' | translate}}" id="smsCode" formControlName="smsCode"
        name="smsCode" type="text" autocomplete="off">
      <button mat-icon-button matSuffix>
        <mat-icon>textsms</mat-icon>
      </button>
      <mat-error *ngIf="smsFrm.get('smsCode').invalid">{{ 'LOGIN.SMSCODE_PLACEHOLDER' | translate}}</mat-error>
    </mat-form-field>
    <mat-error *ngIf="showSMSCodeError">{{'LOGIN.SMSCODE_ERROR' | translate}}</mat-error>
    <button mat-raised-button color="primary" [disabled]="!smsFrm.valid" type="submit">{{'LOGIN.SIGN_IN' | translate}}
    </button>
  </form>
</div>
