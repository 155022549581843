<div class="single-widget-container container">
  <mat-card appearance="outlined">
    <section class="widget login-widget">
      <div class="appLogo text-center">
        <img src="../../../../assets/img/matchedin/matchedin_logo_stor.png" />
      </div>
      <header class="text-center">
        <h4>{{ "CONTRACTS.TITLE" | translate }}</h4>
      </header>
      <label>{{ "LOGIN.SELECT" | translate }}</label>
      <app-language-select></app-language-select>
      <button mat-stroked-button (click)="showConfirm()" color="primary">
        {{ "CONTRACTS.SIGNOUT" | translate | uppercase }}
      </button>
    </section>
  </mat-card>
</div>
