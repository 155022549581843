<div #strength class="my-2">
  <small>{{controlLabel}}</small>
  <ul class="strengthBar">
      <li class="point" [style.background-color]="bar0"></li>
      <li class="point" [style.background-color]="bar1"></li>
      <li class="point" [style.background-color]="bar2"></li>
      <li class="point" [style.background-color]="bar3"></li>
      <li class="point" [style.background-color]="bar4"></li>
      <li class="ML10 help">
        <span class="clickable lg">
          <i class="fa fa-question-circle" popoverTitle="{{'PASSWORD_CRITERIA.REQUIREMENTS' | translate}}"
            [popover]="popTemplate"
            triggers="mouseenter:mouseleave"
            containerClass="customClass"
            [outsideClick]="true"
            placement="top"
            container="body">
          </i>
        </span>
      </li>
  </ul>
</div>

<ng-template #popTemplate>
  <ul class="popList">
      <li>{{'PASSWORD_CRITERIA.CRITERIA_1' | translate}}</li>
      <li>{{'PASSWORD_CRITERIA.CRITERIA_2' | translate}}</li>
      <li>{{'PASSWORD_CRITERIA.CRITERIA_3' | translate}}</li>
      <li>{{'PASSWORD_CRITERIA.CRITERIA_4' | translate}}</li>
      <li>{{'PASSWORD_CRITERIA.CRITERIA_5' | translate}}</li>
  </ul>
</ng-template>