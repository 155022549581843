<div class="container my-4">
  <mat-card appearance="outlined">
    <h1 class="text-center error-code">404</h1>
    <h3 class="text-center">{{ "ERROR_MSG.PAGE_NOT_FOUND" | translate }}</h3>
    <div class="error-container py-5">
      <img
        style="height: 200px"
        src="../../../assets/img/matchedin/matchedin_logo_stor.png"
      />
    </div>
    <p class="text-center py-3">{{ "ERROR_MSG.ERROR_SEARCH" | translate }}</p>
    <mat-progress-bar mode="indeterminate" class="my-4"></mat-progress-bar>
    <p class="text-center">{{ "ERROR_MSG.ERROR_SEARCH1" | translate }}</p>
    <div class="error-container">
      <button mat-raised-button color="primary" [routerLink]="'/'">
        <mat-icon>home</mat-icon> {{ "ERROR_MSG.BACK_HOME" | translate }}
      </button>
    </div>
  </mat-card>
</div>

<!-- <div class="single-widget-container">
  <section class="widget login-widget">
    <div class="appLogo text-center">
      <img src="/assets/img/matchedin_logo_white.png">
    </div>
    <div class="body">
      <div class="container">
        <main id="content" class="error-container" role="main">
          <div class="row">
            <div class="col-12">
              <div class="error-container">
                <h1 class="error-code">404</h1>
                <p class="error-info">
                  Opps, it seems that this page does not exist.
                </p>
              </div>
            </div>
          </div>
          <div class="row MB20">
            <div class="col-12 text-center">
              <button type="button" class="btn btn-info  MR10 " [routerLink]="'/'">
                <i class="fa fa-home MR10" aria-hidden="true"></i>Go back to Home
              </button>
            </div>
          </div>
        </main>
      </div>
    </div>
  </section>
</div> -->
