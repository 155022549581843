<div class="single-widget-container">
  <mat-card appearance="outlined" class="example-card widget login-widget">
    <mat-card-header>
      <div mat-card-avatar class="example-header-image"></div>
      <mat-card-title>{{'FORGOT_PASSWORD.TITLE' | translate}}</mat-card-title>
      <mat-card-subtitle>{{'FORGOT_PASSWORD.ENTER_EMAIL' | translate}}</mat-card-subtitle>
    </mat-card-header>
    <img mat-card-image class="px-3 pb-4" src="/assets/img/matchedin/matchedin_logo_stor.png" alt="matchedin_logo_stor">
    <form [formGroup]="frm" (ngSubmit)="forgotPassword()">
    <mat-card-content>
      <app-language-select class="example-container"></app-language-select>
      
        <mat-form-field class="example-container">
          <!-- <input matInput id="email" [(ngModel)]="email" name="email" type="email" required placeholder="{{'FORGOT_PASSWORD.EMAIL' | translate}}">
          <mat-icon matSuffix>email</mat-icon> -->

          <input matInput placeholder="{{'FORGOT_PASSWORD.EMAIL' | translate}}" formControlName="username" id="username"
              name="username" type="email">
            <button mat-icon-button tabindex="-1" matSuffix>
              <mat-icon>mail_outline</mat-icon>
            </button>
            <mat-error *ngIf="frm.get('username').errors?.required">required</mat-error>
            <mat-error *ngIf="frm.get('username').errors?.email">email needed</mat-error>
        </mat-form-field>
      
    </mat-card-content>
    <mat-card-actions>
        <!-- <button mat-raised-button color="primary" [disabled]="!frm.valid"
        type="submit">{{'LOGIN.SIGN_IN' | translate}}</button>
      <br>
      <br>
      <button mat-raised-button color="secondary" (click)="gotForgotPassword()"
        type="button">{{'LOGIN.FORGOT' | translate}}</button> -->

      <!-- <button mat-raised-button color="accent" routerLink="/login">{{'LOGIN.SIGN_IN' | translate | uppercase }}</button> -->
      <button mat-raised-button color="primary" type="submit" [disabled]="!frm.valid">{{ 'FORGOT_PASSWORD.SEND_REMINDER' | translate | uppercase }} </button>
    </mat-card-actions>
  </form>
  </mat-card>
</div> 

<!-- <div class="single-widget-container">

  <section class="widget login-widget">
    <div class="appLogo text-center MB20">
      <img src="/assets/img/matchedin_logo_white.png">
    </div>
    <header class="text-xs-center">
      <h4>{{'FORGOT_PASSWORD.TITLE' | translate}}</h4> - {{'FORGOT_PASSWORD.ENTER_EMAIL' | translate}}
    </header>
    <div class="pull-right">
        <label>{{'LOGIN.SELECT' | translate}}</label>
        <app-language-select></app-language-select>
      </div>
    <div class="body">
      <form class="no-margin" action="index.html" method="get">
        <fieldset class="login-form">
          <div class="form-group">
            <label for="password">{{'FORGOT_PASSWORD.EMAIL' | translate}}</label>
            <div class="input-group input-group-lg ">
              <span class="input-group-addon input-group-prepend">
                <i class="fa fa-user input-group-text"></i>
              </span>
              <input id="email" [(ngModel)]="email" name="email" type="email" class="form-control input-lg input-transparent" placeholder="{{'FORGOT_PASSWORD.EMAIL' | translate}}">
            </div>
          </div>
        </fieldset>

        <div class="form-actions">
          <button type="submit" (click)="forgotPassword()" class="btn btn-block btn-danger">
            <span class="small-circle">
              <i class="fa fa-caret-right"></i>
            </span>
            <small>{{'FORGOT_PASSWORD.SEND_REMINDER' | translate}} </small>
          </button>
          <a routerLink="/login" class="forgot">{{'FORGOT_PASSWORD.LOGIN' | translate}}</a>
        </div>
      </form>
    </div>
    <footer>
    </footer>
  </section>
</div> -->