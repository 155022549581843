import { Routes } from "@angular/router";
import { ErrorComponent } from "./modules/error/error.component";
import { AuthGuard } from "./modules/auth/authGuard";
import { LoginModule } from "./modules/login/login.module";
import { ActiveUserComponent } from "./modules/login/active-user/active-user.component";
import { ResetPasswordComponent } from "./modules/login/reset-password/reset-password.component";
import { MATCHEDIN } from "./app.constants";
import { AppComponent } from "./app.component";
import { HomeComponent } from "./modules/home/home.component";

export const ROUTES: Routes = [
  { path: "", component: AppComponent },
  // { path: "", redirectTo: "login", pathMatch: "full" },
  {
    path: MATCHEDIN.ROUTERLINKS.APP,
    loadChildren: () => import('./modules/layout/layout.module').then(m => m.LayoutModule),
    canActivate: [AuthGuard]
  },
  {
    path: MATCHEDIN.ROUTERLINKS.HOME,
    component: HomeComponent
  },
  {
    path: MATCHEDIN.ROUTERLINKS.LOGIN,
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule)
  },
  { path: MATCHEDIN.ROUTERLINKS.ACTIVATE_USER, component: ActiveUserComponent },
  {
    path: MATCHEDIN.ROUTERLINKS.RESET_PASSWORD,
    component: ResetPasswordComponent
  },
  { path: MATCHEDIN.ROUTERLINKS.ERROR, component: ErrorComponent },
  { path: "**", component: ErrorComponent }
];
