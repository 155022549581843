<div class="container-fluid my-2">
  <app-previous-page></app-previous-page>
  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title>
        <i class="material-icons">
          report_problem
        </i>
        {{ "PASSOWRD_RESET.PASSWORD_RESET" | translate }}
      </mat-card-title>
      <mat-card-subtitle>{{
        "PASSOWRD_RESET.SET_PASSWORD" | translate
      }}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <form action="index.html" method="get">
        <!-- <mat-form-field class="example-full-width">
          <input
            matInput
            placeholder="{{ 'PASSOWRD_RESET.CODE' | translate }}"
            value="Code"
            id="code"
            [(ngModel)]="code"
            name="code"
          />
        </mat-form-field>
        <br /> -->
        <mat-form-field class="example-full-width">
          <input
            matInput
            placeholder="{{ 'PASSOWRD_RESET.PASSWORD' | translate }}"
            id="password"
            [(ngModel)]="password"
            name="password"
            type="password"
          />
        </mat-form-field>
        <br />
        <mat-form-field class="example-full-width">
          <input
            matInput
            placeholder="{{ 'PASSOWRD_RESET.CONFIRM_PASSWORD' | translate }}"
            id="cpassword"
            [(ngModel)]="confirmpassword"
            name="confirmpassword"
            type="password"
          />
        </mat-form-field>
        <mat-card-actions>
            <div *ngIf="passwordcriteriafail">passwordcriteriafail</div>
            <div *ngIf="resetpasswordCodeExpired">resetpasswordCodeExpired</div>
            <div *ngIf="resetpasswordCodeError">resetpasswordCodeError</div>
          <button mat-raised-button color="primary" routerLink="/login">{{"FORGOT_PASSWORD.LOGIN" | translate }}</button>
          <button mat-raised-button color="primary" (click)="resetPassword()">{{ "PASSOWRD_RESET.SET_PASSWORD_BTN" | translate }}</button>
        </mat-card-actions>
      </form>
    </mat-card-content>
  </mat-card>
</div>

<!-- <div class="single-widget-container">
  <section class="widget login-widget">
    <div class="appLogo text-center MB20">
      <img src="/assets/img/matchedin_logo_white.png" />
    </div>
    <header class="text-xs-center">
      <h4>{{ "PASSOWRD_RESET.PASSWORD_RESET" | translate }}</h4>
      - {{ "PASSOWRD_RESET.SET_PASSWORD" | translate }}
    </header>
    <div class="pull-right">
      <label>{{ "LOGIN.SELECT" | translate }}</label>
      <app-language-select></app-language-select>
    </div>
    <div class="body">
      <form class="no-margin" action="index.html" method="get">
        <fieldset class="login-form">
          <div class="form-group">
            <label for="Code">{{ "PASSOWRD_RESET.CODE" | translate }}</label>
            <div class="input-group input-group-lg">
              <span class="input-group-addon input-group-prepend">
                <i class="fa fa-lock input-group-text"></i>
              </span>
              <input
                id="code"
                [(ngModel)]="code"
                name="code"
                type="text"
                class="form-control input-lg input-transparent"
                placeholder="{{ 'PASSOWRD_RESET.CODE' | translate }}"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="password">{{
              "PASSOWRD_RESET.PASSWORD" | translate
            }}</label>
            <div class="input-group input-group-lg">
              <span class="input-group-addon input-group-prepend">
                <i class="fa fa-lock input-group-text"></i>
              </span>
              <input
                id="password"
                [(ngModel)]="password"
                name="password"
                type="password"
                class="form-control input-lg input-transparent"
                placeholder="{{ 'PASSOWRD_RESET.PASSWORD' | translate }}"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="confirmpassword">{{
              "PASSOWRD_RESET.CONFIRM_PASSWORD" | translate
            }}</label>
            <div class="input-group input-group-lg">
              <span class="input-group-addon input-group-prepend">
                <i class="fa fa-lock input-group-text"></i>
              </span>
              <input
                id="cpassword"
                [(ngModel)]="confirmpassword"
                name="confirmpassword"
                type="password"
                class="form-control input-lg input-transparent"
                placeholder="{{
                  'PASSOWRD_RESET.CONFIRM_PASSWORD' | translate
                }}"
              />
            </div>
          </div>
        </fieldset>
        <div class="form-actions">
          <div *ngIf="passwordcriteriafail">passwordcriteriafail</div>
          <div *ngIf="resetpasswordCodeExpired">resetpasswordCodeExpired</div>
          <div *ngIf="resetpasswordCodeError">resetpasswordCodeError</div>
          <button
            type="submit"
            (click)="resetPassword()"
            class="btn btn-block btn-danger"
          >
            <span class="small-circle">
              <i class="fa fa-caret-right"></i>
            </span>
            <small>{{ "PASSOWRD_RESET.SET_PASSWORD_BTN" | translate }}</small>
          </button>
          <a routerLink="/login" class="forgot">{{
            "FORGOT_PASSWORD.LOGIN" | translate
          }}</a>
        </div>
      </form>
    </div>
    <footer>
    </footer>
  </section>
</div> -->
