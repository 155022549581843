<mat-dialog-content class="mat-typography ">
  <div class="appLogo text-center mb-4">
    <img
      src="../../../assets/img/matchedin/matchedin_logo_stor.png"
      alt="matchedin_logo_stor"
    />
  </div>
  <h1>{{ "ACTIVATE_USER.PRIVACY_POLOCY" | translate }}</h1>
  <br />

  <h2>
    Vi på Matchedin värnar om din personliga integritet och vill att du ska
    känna dig trygg i att vi hanterar dina personliga uppgifter på ett
    ansvarsfullt sätt. I den här integritetspolicyn hittar du information om hur
    vi hanterar och använder personuppgifter enligt gällande lagstiftning. Den
    beskriver också dina rättigheter och hur du kan använda dem.
  </h2>
  <h2>Vilken information samlar vi in?</h2>
  <p>
    Vi samlar in information från dig när du fyller i ett formulär på webbplats,
    registrerar din e-postadress eller för en dialog med oss. Vi lagrar ditt
    namn, dina personuppgifter, din e-postadress, ditt telefonnummer,
    företagsuppgifter och de personliga uppgifter du väljer att svara på i vår
    matchningsportal för att använda vår tjänst.
  </p>
  <p>
    Viss information vi samlar in kan bedömas som känsliga personuppgifter och
    det är av största vikt att du är medveten om att du frivilligt väljer att
    lämna dessa för att kunna använda Matchedins matchningsplattform. Du är
    själv ägare av dessa uppgifter och de kommer inte användas till andra
    ändamål än matchning mot arbetsuppgifter.
  </p>
  <p>
    Om du besöker vår webbplats sparar vi information om din enhet och
    webbläsare med hjälp av cookies.
  </p>
  <h2>
    Vad är cookies och hur använder vi det?
  </h2>
  <p>
    Cookies är en liten textfil som skickas från vår webbserver när du besöker
    vår webbplats. Cookies sparas i din webbläsare och/eller enhet. Vi använder
    cookies för att kunna ge dig en personlig upplevelse och för att webbplatsen
    ska fungera på bästa sätt.
  </p>
  <h2>Varför behöver vi dina uppgifter?</h2>
  <p>
    Vi behandlar personuppgifter för följande ändamål:
  </p>
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

    <!-- reason Column -->
    <ng-container matColumnDef="reason">
      <th mat-header-cell *matHeaderCellDef>
        <h3>
          Ändamål med behandlingen (Varför vi samlar in uppgifter)
        </h3>
      </th>
      <td mat-cell *matCellDef="let element">{{ element.reason }}</td>
    </ng-container>

    <!-- legal Column -->
    <ng-container matColumnDef="legal">
      <th mat-header-cell *matHeaderCellDef>
        <h3>
          Laglig grund för behandlingen (Varför behandlingen är nödvändig)
        </h3>
      </th>
      <td mat-cell *matCellDef="let element">{{ element.legal }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <h2 class="mt-4">Vilka kan vi komma att dela dina personuppgifter med?</h2>
  <p>
    Vi säljer inte dina personuppgifter och delar inte dem med andra företag
    eller organisationer utan ditt medgivande.
  </p>
  <p>
    Vi kan dela dina kontaktuppgifter och matchningsunderlag till företag och
    organisationer i syfte att leverera vår matchningstjänst. Detta först efter
    att du givit medgivande i systemet att dessa ska kunna delas i detta syfte i
    samband med att du gjort klart Matchningsformuläret.
  </p>
  <p>
    I vissa fall kan vi dock dela med oss av personuppgifter för att kunna
    nyttja system hos betrodd tredje part, till exempel ett CRM-system eller
    molntjänst. Vid delning ser vi till att överföringen genomförs på ett säkert
    sätt.
  </p>
  <h2>
    Var behandlar vi dina personuppgifter?
  </h2>
  <p>Vi strävar efter att dina personuppgifter ska behandlas inom EU/EES.</p>
  <h2>
    Hur länge sparar vi dina personuppgifter?
  </h2>
  <p>
    Dina personuppgifter sparas inte längre än nödvändigt. Lagringsperioden
    beror på ändamålet och kan variera från tre månader upp till några år från
    vår senaste kontakt.
  </p>
  <h2>Vad har du för rättigheter som registrerad?</h2>
  <p>
    Det är viktigt att påpeka att du har möjlighet att påverka hur dina
    personuppgifter används. Du ska kunna ha kontroll över dina egna uppgifter,
    enligt gällande dataskyddslagstiftning har du rätt till följande:
  </p>
  <mat-list role="list">
    <mat-list-item role="listitem">
      <mat-icon mat-list-icon>check</mat-icon>
      <h4 mat-line>Rätt till tillgång (registerutdrag)</h4>
      <p mat-line>
        Du har rätt att begära ett utdrag över vilka registrerade
        personuppgifter vi har om dig.
      </p>
    </mat-list-item>
    <mat-list-item role="listitem">
      <mat-icon mat-list-icon>check</mat-icon>
      <h4 mat-line>Rätt till rättelse</h4>
      <p mat-line>
        Du har rätt att korrigera felaktig eller ej komplett information om dig
        korrigerad.
      </p>
    </mat-list-item>
    <mat-list-item role="listitem">
      <mat-icon mat-list-icon>check</mat-icon>
      <h4 mat-line>Rätt att bli raderad</h4>
      <p mat-line>
        Du har rätt att begära att dina personuppgifter raderas om uppgifterna
        inte längre är nödvändiga för det syfte de samlades in för.
      </p>
      <p mat-line>
        Vill du att vi raderar dina personuppgifter, vänligen hör av dig till
        <a href="mailto:info@matchedin.se">info@matchedin.se</a>
      </p>
    </mat-list-item>
    <mat-list-item role="listitem">
      <mat-icon mat-list-icon>check</mat-icon>
      <h4 mat-line>Rätt till dataportabilitet</h4>
      <p mat-line>
        Du kan ha rätt att begära att få ut dina uppgifter i ett maskinläsbart
        format för att återanvända uppgifterna hos ett annat företag.
      </p>
      <p mat-line class="text-danger">
        Ej tillämpligt hos oss om det inte är ett lagkrav.
      </p>
    </mat-list-item>
    <mat-list-item role="listitem">
      <mat-icon mat-list-icon>check</mat-icon>
      <h4 mat-line>Rätt att göra invändningar mot viss typ av behandling</h4>
      <p mat-line>
        Du har rätt att avstå från till exempel nyhetsbrev och neka användning
        av dina personuppgifter som bygger på ett berättigat intresse.
      </p>
    </mat-list-item>
    <mat-list-item role="listitem">
      <mat-icon mat-list-icon>check</mat-icon>
      <h4 mat-line>Rätt att begränsa behandling av personuppgifter</h4>
      <p mat-line>
        Om du till exempel anser att dina personuppgifter inte är korrekta har
        du rätt att begränsa behandlingen av dessa.
      </p>
    </mat-list-item>
    <mat-list-item role="listitem">
      <mat-icon mat-list-icon>check</mat-icon>
      <h4 mat-line>Rätt att begränsa matchningstjänsten av dina uppgifter</h4>
      <p mat-line>
        Om du till exempel har fått en anställning och inte vara synlig i
        matchningstjänsten kan du begränsa användandet av dina uppgifter i
        systemet för det syftet.
      </p>
    </mat-list-item>
  </mat-list>
  <h2>Hur skyddas dina personuppgifter?</h2>
  <p>
    Vi använder en kombination av tekniska och organisatoriska säkerhetsåtgärder
    för att skydda dina personuppgifter. Endast de personer som behöver behandla
    dina personuppgifter för att kunna uppfylla angivna ändamål har tillgång
    till dem. De med tillgång till dina uppgifter ska följa vår interna
    informationssäkerhetspolicy.
  </p>
  <h2>Vem är ansvarig för de personuppgifter vi samlar in?</h2>
  <mat-card appearance="outlined" class="example-card">
    <mat-card-header>
      <!-- <div mat-card-avatar class="example-header-image"></div> -->
      <mat-card-title>Matchedin AB</mat-card-title>
      <mat-card-subtitle>Organisationsnummer 559013- 7716</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <p>Personuppgiftsansvarig hos oss är Miro Madeira med postadress:</p>
      <mat-list-item>
        <mat-icon mat-list-icon>location_on</mat-icon>
        <p mat-line>Wallingatan 42</p>
        <p mat-line>111 24 Stockholm</p>
        <p mat-line><a href="mailto:info@matchedin.se">info@matchedin.se</a></p>
      </mat-list-item>
    </mat-card-content>
  </mat-card>
  <h2 class="mt-4">Har du frågor?</h2>
  <p>
      Undrar du över något eller är du bara väldigt intresserad, tveka inte att höra
      av dig! Kontakta oss gärna via mail: <a href="mailto:info@matchedin.se">info@matchedin.se</a>
  </p>
  <h2>
    Med vänliga hälsningar, <br>
    Vi på Matchedin
  </h2>
  <mat-dialog-actions>
      <button [mat-dialog-close] mat-raised-button color="primary" tabindex="-1">OK</button>
    </mat-dialog-actions>
</mat-dialog-content>
