<mat-sidenav-container class="sidenav-container" (backdropClick)="close('backdrop')">
  <mat-sidenav #drawer class="sidenav" fixedInViewport="true"
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'">
    <mat-toolbar>
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      Menu
    </mat-toolbar>
    <mat-nav-list *ngIf="authService.loggedIn()">
      <mat-list-item routerLink="/">
        <mat-icon mat-list-icon>home</mat-icon>
        <h4 mat-line>{{'HOME.START' | translate}}</h4>
      </mat-list-item>
      <mat-list-item routerLink="app/mypage/stepper">
        <mat-icon mat-list-icon>question_answer</mat-icon>
        <h4 mat-line>{{'SIDEBAR.STEPPER' | translate}}</h4>
      </mat-list-item>
      <mat-list-item routerLink="app/mypage/account">
        <mat-icon mat-list-icon>person</mat-icon>
        <h4 mat-line>{{'SIDEBAR.MY_PAGE' | translate}}</h4>
      </mat-list-item>
    </mat-nav-list>

    <mat-nav-list
      *ngIf="authService.loggedIn() && authService.getRoleName() && (authService.getRoleName().indexOf('SiteAdmin') > -1 || authService.getRoleName().indexOf('Administrator') > -1)">
      <mat-list-item routerLink="app/enrollment/enrollment">
        <mat-icon mat-list-icon>person_add</mat-icon>
        <h4 mat-line>{{'SIDEBAR.Enrollment' | translate}}</h4>
      </mat-list-item>
      <mat-list-item routerLink="app/questionform">
        <mat-icon mat-list-icon>poll</mat-icon>
        <h4 mat-line>{{'SIDEBAR.Questions' | translate}}</h4>
      </mat-list-item>
      <mat-list-item routerLink="app/users/users-list">
        <mat-icon mat-list-icon>people</mat-icon>
        <h4 mat-line>{{'SIDEBAR.List Users' | translate}}</h4>
      </mat-list-item>
      <mat-list-item routerLink="app/questionform">
        <mat-icon mat-list-icon>poll</mat-icon>
        <h4 mat-line>{{'SIDEBAR.Questions' | translate}}</h4>
      </mat-list-item>
      <!-- <mat-list-item routerLink="app/dashboard">
        <mat-icon mat-list-icon>dashboard</mat-icon>
        <h4 mat-line>{{ "DASHBOARD.TITLE" | translate }}</h4>
      </mat-list-item> -->
      <mat-list-item routerLink="app/matchform">
        <mat-icon mat-list-icon>vertical_align_center</mat-icon>
        <h4 mat-line>{{'SIDEBAR.Match Form' | translate}}</h4>
      </mat-list-item>
      <!-- <mat-list-item routerLink="app/matchform/detail">
        <mat-icon mat-list-icon>edit</mat-icon>
        <h4 mat-line>{{'MATCHFORM.EDIT_TITLE' | translate}}</h4>
      </mat-list-item> -->
    </mat-nav-list>

    <mat-nav-list *ngIf="!authService.loggedIn()">
      <mat-list-item (click)="openDialog()">
        <mat-icon mat-list-icon matTooltip="{{ 'LOGIN_MODAL.LOGIN' | translate | uppercase }}">vpn_key</mat-icon>
        <h4 mat-line>{{'LOGIN.MENUTITLE' | translate}}</h4>
      </mat-list-item>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button *ngIf="authService.loggedIn()" type="button" aria-label="Toggle sidenav" mat-icon-button
        (click)="drawer.toggle()">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span class="spacer"></span>
      <img [routerLink]="['/']" class="applogo clickable" src="/assets/img/matchedin_logo_white.png">
      <span class="spacer"></span>
      <button mat-button *ngIf="!authService.loggedIn()" type="button" aria-label="Login" (click)="openDialog()"
        matTooltip="{{ 'LOGIN_MODAL.LOGIN' | translate | uppercase }}">{{'LOGIN.MENUTITLE' | translate | uppercase}}
      </button>
      <button mat-button *ngIf="authService.loggedIn()" type="button" aria-label="Logout"
        (click)="authService.logout()">{{'LOGIN.SIGN_OUT' | translate | uppercase }}
      </button>
      <!-- <button mat-raised-button color="accent" (click)="authService.forceTimeOut()"> Force TimeOut </button>
      <button mat-raised-button color="accent" (click)="authService.forceTimeOutWarning()"> Force Warning </button> -->
      <!-- <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button> -->

    </mat-toolbar>
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>

<!-- 
<mat-sidenav-container class="sidenav-container" (backdropClick)="close('backdrop')">
  <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === true">
    <mat-toolbar>
      <app-language-select style="height: 30px"></app-language-select>
    </mat-toolbar>
    <mat-nav-list>
      <mat-list-item routerLink="app/mypage/account">
        <mat-icon mat-list-icon>person</mat-icon>
        <h4 mat-line>{{'SIDEBAR.My Page' | translate}}</h4>
      </mat-list-item>
      <mat-list-item routerLink="app/enrollment/enrollment">
        <mat-icon mat-list-icon>person_add</mat-icon>
        <h4 mat-line>{{'SIDEBAR.Enrollment' | translate}}</h4>
      </mat-list-item>
      <mat-list-item routerLink="app/questionform">
        <mat-icon mat-list-icon>poll</mat-icon>
        <h4 mat-line>{{'SIDEBAR.Questions' | translate}}</h4>
      </mat-list-item>
      <mat-list-item routerLink="app/users/users-list">
        <mat-icon mat-list-icon>people</mat-icon>
        <h4 mat-line>{{'SIDEBAR.List Users' | translate}}</h4>
      </mat-list-item>
      <mat-list-item routerLink="app/dashboard">
        <mat-icon mat-list-icon>dashboard</mat-icon>
        <h4 mat-line>{{ "DASHBOARD.TITLE" | translate }}</h4>
      </mat-list-item>
      <mat-list-item routerLink="app/matchform">
        <mat-icon mat-list-icon>vertical_align_center</mat-icon>
        <h4 mat-line>{{'SIDEBAR.Match Form' | translate}}</h4>
      </mat-list-item>
      <mat-list-item routerLink="app/matchform/detail">
        <mat-icon mat-list-icon>edit</mat-icon>
        <h4 mat-line>{{'MATCHFORM.EDIT_TITLE' | translate}}</h4>
      </mat-list-item>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar>
      <img [routerLink]="['/']" class="applogo clickable" src="/assets/img/matchedin_logo_white.png">
      <span class="spacer"></span>
      <button mat-button *ngIf="!authService.loggedIn()" type="button" aria-label="Open Login" (click)="openDialog()">
        {{ 'LOGIN.SIGN_IN' | translate | uppercase }}
      </button>
      <button mat-button *ngIf="authService.loggedIn()" type="button" aria-label="Logout"
        (click)="authService.logout()">
        {{'LOGIN.SIGN_OUT' | translate | uppercase }}
      </button>

      <button mat-raised-button color="accent" (click)="authService.forceTimeOut()"> Force TimeOut </button>

      <button mat-raised-button color="accent" (click)="authService.forceTimeOutWarning()"> Force Warning </button>
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
    </mat-toolbar>
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container> -->
