<div class="container mt-2">
  <mat-card appearance="outlined" class="text-center toaster" [class.toaster-success]="type === 'success'"
    [class.toaster-danger]="type === 'danger'">
    <header class="text-center" *ngIf="header">
      <h3>{{ header | translate }}</h3>
    </header>
    <p class="message">
      {{ message | translate }}
    </p>
  </mat-card>
</div>
