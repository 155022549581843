
import {throwError as observableThrowError,  Observable ,  Subject } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import 'rxjs/Rx';

import { AuthService } from '../../modules/auth/auth.service';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class ContractsService {
  private hostPath: string = JSON.parse(environment.IDENTITY);

  private contractSignedSubject = new Subject<any>();
  contractSignedEmitted$ = this.contractSignedSubject.asObservable();

  private contractChangedSubject = new Subject<any>();
  contractChangedEmitted$ = this.contractChangedSubject.asObservable();

  constructor(private authHttp: HttpClient, public router: Router, public authService: AuthService) {
  }

  public signContract(data): Observable<any> {
    //   {
    //     public string Site { get; set; }
    //     public string Password { get; set; }
    //     public Guid ContractId { get; set; }
    //     public Guid? OrganizationId { get; set; }
    // }
    data.site = 'min.matchedin.com';
    console.log('before data in service', data);

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.authHttp.post<any>(this.hostPath + 'cjsf/user/signcontract', data, { headers }).pipe(
      map((result: any) => {
        return result;
      }), catchError((error: any) => {
        this.router.navigate(['/login']);
        const errMsg = (error.message) ? error.message :
          error.status ? `${error.status} - ${error.statusText}` : 'Server error';
        console.error('auth.service - Handled error: ' + errMsg); // log to console for now
        return observableThrowError(error);
      }), );
  }

  /**
  * check for "hasMandatoryUnsignedContracts" key from JWT token
  */
  public checkMandatoryFlag(): boolean {
    if (this.authService.getHasMandatoryUnsignedContracts().toLocaleLowerCase() === 'true') {
      return true;
    }
    return false;
  }


  /**
   * accepts a list of contracts and checks whether contracts are signed or not and mustBeSignedBefore is already due
   * @param contractsList
   */
  public checkUnsignedContracts(contractsList): boolean {
    let found = 0;
    const currentDate = new Date();
    if (contractsList.contracts) {
      contractsList.contracts.forEach(contract => {
        const cDate = new Date(contract.mustBeSignedBefore);
        if (!contract.signed && (cDate < currentDate)) {
          found++;
        }
      });
    }
    if (found > 0) {
      return true;
    } else {
      return false;
    }
  }


  /**
   * This method checks for whether redirected to contracts page or not
   */
  public routeToContracts(): boolean {
    if (this.authService.getHasMandatoryUnsignedContracts() &&
      this.authService.getHasMandatoryUnsignedContracts().toLocaleLowerCase() === 'true') {
      return true;
    } else if (localStorage.getItem('ContractsList') != null) {
      return this.checkUnsignedContracts(JSON.parse(localStorage.getItem('ContractsList')));
    } else {
      return false;
    }
  }

  /**
   *
   */
  public contractSigned(signed: boolean) {
    this.contractSignedSubject.next(signed);
  }

  /**
   * This method lets all subscribers to know the contracts have changed
   */
  public contractsUpdated() {
    this.contractChangedSubject.next();
  }
}
