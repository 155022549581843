
import {forkJoin as observableForkJoin,  Observable } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ContractsService } from '../../../helpers/services/contracts.service';
import { ModalConfirmComponent } from '../../shared/modal-confirm/modal-confirm.component';
import { AuthService } from '../../auth';
import { TranslateService } from '@ngx-translate/core';
import { LanguageUtilService } from '../../auth/language-util.service';
import { Subscription } from '../../../../../node_modules/rxjs';

@Component({
  selector: 'app-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.scss']
})
export class ContractsComponent implements OnInit, OnDestroy {
  public contractForm: UntypedFormGroup;
  public contractList;
  public subscription: Subscription;

  constructor(private contractService: ContractsService, public authService: AuthService, private router: Router,
    public translate: TranslateService, private languageUtilService: LanguageUtilService) {
    this.subscription = this.contractService.contractSignedEmitted$.subscribe(success => {
      // read the updated contract list from localstorage, because localstorage is updated when contracts are updated
      if (localStorage.getItem('ContractsList') != null) {
        this.contractList = JSON.parse(localStorage.getItem('ContractsList'));
        if (success) {
          if (!this.contractService.checkUnsignedContracts(this.contractList)) {
            // if all contracts are signed or contracts that are not signed have due date greater than today.
            const userRoles = this.authService.getRoleName();
            if (userRoles && userRoles.length > 0) {
              if (this.authService.isAdmin() || this.authService.isSiteAdmin() || this.authService.isAdminOrSiteAdmin()) {
                this.router.navigate(['/app/dashboard']);
              } else {
                this.router.navigate(['/app/404']);
              }
            } else {
              this.router.navigate(['/app/mypage/account']);
            }
          }
        }
      }
    });
  }

  ngOnInit() {

  }


  public showConfirm() {
    alert('need to fix this in contracts component')
    // observableForkJoin(
    //   this.translate.get('LOGOUT.CONFIRMATION'),
    //   this.translate.get('LOGOUT.MESSAGE'),
    //   this.translate.get('LOGOUT.YES'),
    //   this.translate.get('LOGOUT.NO')
    // ).subscribe(data => {
    //   this.dialogService.addDialog(ModalConfirmComponent, {
    //     title: data[0],
    //     message: data[1],
    //     leftButton: data[2],
    //     rightButton: data[3]
    //   }, { closeByClickingOutside: true })
    //     .subscribe((isConfirmed) => {
    //       if (isConfirmed) {
    //         this.authService.logout();
    //       } else {
    //         return;
    //       }
    //     });
    // },
    //   err => {
    //     console.log('error');
    //   });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
