<!-- <select name="langSelect" class="form-control input-lg input-transparent custom" #langSelect
  (change)="translate.use(langSelect.value);changeLanguage(langSelect.value);">
  <option value="en" [selected]="translate.currentLang === 'en'">English</option>
  <option value="sv" [selected]="translate.currentLang === 'sv'">Svenska</option>
</select> -->

<mat-form-field class="example-container">
  <mat-label>{{'LOGIN.SELECT' | translate }}</mat-label>
  <select matNativeControl name="langSelect" #langSelect
    (change)="translate.use(langSelect.value);changeLanguage(langSelect.value);">
    <option value="en" [selected]="translate.currentLang === 'en'">English</option>
    <option value="sv" [selected]="translate.currentLang === 'sv'">Svenska</option>
  </select>
</mat-form-field>

<!-- <mat-form-field class="example-container">
  <mat-label>{{ "LOGIN.SELECT" | translate }}</mat-label>
  <mat-select name="langSelect" #langSelect
    (change)="translate.use(langSelect.value); changeLanguage(langSelect.value)">
    <mat-option value="en" selected="translate.currentLang === 'en'">English</mat-option>
    <mat-option value="sv" selected="translate.currentLang === 'sv'">Svenska</mat-option>
  </mat-select>
</mat-form-field> -->