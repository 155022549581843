<div class="toasters">
  <div *ngFor="let toaster of toasters; trackBy: toasterId" 
    toaster 
    [type]="toaster.type" 
    [header]="toaster.header"
    [message]="toaster.message" 
    [iconPath]="toaster.icon" 
    (click)="onClick(toaster, $event)" 
    [@fadeInOut]="'in'">
  </div>
</div>
